import(/* webpackMode: "eager" */ "/app/src/component/accordion/AccordionSection.tsx");
import(/* webpackMode: "eager" */ "/app/src/component/banner/BannerSection.tsx");
import(/* webpackMode: "eager" */ "/app/src/component/banner/BannerSection2.tsx");
import(/* webpackMode: "eager" */ "/app/src/component/banner/BannerSection3.tsx");
import(/* webpackMode: "eager" */ "/app/src/component/blog/AllBlogSection.tsx");
import(/* webpackMode: "eager" */ "/app/src/component/blog/BlogSection.tsx");
import(/* webpackMode: "eager" */ "/app/src/component/blog/BlogSection2.tsx");
import(/* webpackMode: "eager" */ "/app/src/component/footer/FooterSection.tsx");
import(/* webpackMode: "eager" */ "/app/src/component/footer/FooterSection2.tsx");
import(/* webpackMode: "eager" */ "/app/src/component/form/CtaForm.tsx");
import(/* webpackMode: "eager" */ "/app/src/component/gallery/GallerySection.tsx");
import(/* webpackMode: "eager" */ "/app/src/component/gallery/GallerySection2.tsx");
import(/* webpackMode: "eager" */ "/app/src/component/header/HeaderSection.tsx");
import(/* webpackMode: "eager" */ "/app/src/component/header/HeaderSection2.tsx");
import(/* webpackMode: "eager" */ "/app/src/component/header/HeaderSection3.tsx");
import(/* webpackMode: "eager" */ "/app/src/component/header/HeaderSection4.tsx");
import(/* webpackMode: "eager" */ "/app/src/component/info/InfoSection.tsx");
import(/* webpackMode: "eager" */ "/app/src/component/modal/CartModal.tsx");
import(/* webpackMode: "eager" */ "/app/src/component/modal/SearchFormModal.tsx");
import(/* webpackMode: "eager" */ "/app/src/component/modal/WishlistModal.tsx");
import(/* webpackMode: "eager" */ "/app/src/component/partner/PartnerSection.tsx");
import(/* webpackMode: "eager" */ "/app/src/component/portfolio/PortfolioSection.tsx");
import(/* webpackMode: "eager" */ "/app/src/component/product/ProductSection.tsx");
import(/* webpackMode: "eager" */ "/app/src/component/project/ProjectSection.tsx");
import(/* webpackMode: "eager" */ "/app/src/component/project/ProjectSection2.tsx");
import(/* webpackMode: "eager" */ "/app/src/component/project/ProjectSection3.tsx");
import(/* webpackMode: "eager" */ "/app/src/component/service/ServiceSection.tsx");
import(/* webpackMode: "eager" */ "/app/src/component/shop/ShopCard.tsx");
import(/* webpackMode: "eager" */ "/app/src/component/speaker/SpeakerSection.tsx");
import(/* webpackMode: "eager" */ "/app/src/component/team/TeamSection.tsx");
import(/* webpackMode: "eager" */ "/app/src/component/testimonial/TestimonialSection.tsx");
import(/* webpackMode: "eager" */ "/app/src/component/testimonial/TestimonialSection2.tsx");
import(/* webpackMode: "eager" */ "/app/src/component/utils/CustomGsapHeaderAnimate.tsx");
import(/* webpackMode: "eager" */ "/app/src/component/utils/DivAnimateYAxis.tsx");
import(/* webpackMode: "eager" */ "/app/src/component/utils/NumberCounter.tsx");
import(/* webpackMode: "eager" */ "/app/src/component/video/VideoSection.tsx")